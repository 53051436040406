.edit-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

h2 {
  color: #DE1A1A;
  font-size: 1.25rem;
}

.edit-container {
  opacity: 1;
  background-color: white;
  margin: 10% auto;
  height: 65%;
  width: 60%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  animation-name: uploadopen;
  animation-duration: 1s;
  text-align: center;
}

.close-btn {
  color: #ccc;
  float: right;
  font-size: 30px;
  color: grey;
}

.edit-form {
  margin: 1em auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 75%;
  width: 75%;
}

.edit-form > label {
  color: #DE1A1A;
}

.edit-btn {
  border: none;
  background: #000;
  margin-top: 1em;
  color: #DE1A1A;
  padding: .5em;
  font-weight: bold;
}

.edit-btn:hover {
  color: #95b46a;
}

.close-btn:hover,
.close-btn:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}