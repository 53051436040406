.navbar {
  display: flex;
  justify-content: flex-start;
  background-color: #363732;
  height: 3.25rem;
  align-items: center;
}

.nav-links {
  list-style-type: none;
  display: inline;
  margin-left: auto;
  margin-right: .8em;
}

.nav-links > li{
  display: inline;
}

.nav-btn {
    background: none;
    border: none;
    padding: 0;
    color: #F4FAFF;
    text-decoration: none;
    cursor: pointer;
    font-size: 1.15rem;
}

.login-btn {
  margin-right: .8em;
  font-weight: bold;
}

.nav-btn:hover, .nav-btn:focus {
  border-bottom: inset;
  border-color: #95B46A;
  border-width: .2em;
  color: #95B46A;
}

.nav-thumb {
  max-width: 25px;
  margin-right: .3em;
} 

.search-btn {
  margin-left: 1em;
}

.login-box {
  text-align: center;
  margin-top: .3em;
  position: absolute;
  right: 5px;
  background-color: grey;
  display: flex;
  flex-basis: 10px;
  align-items: center;
  opacity: .95;
}

.profile-nav {
  margin-left: .3em;
  display: flex;
  align-items: center;
}

