.loading span {
  width: 25px;
  height: 25px;
  margin: 0 5px;
  background-color: #95B46A;
  border-radius: 50%;
  display: inline-block;
  animation-name: dots;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

.loading {
  margin-top: 4em;
}

.loading span:nth-child(2) {
  background-color: #95B46A;
  animation-delay: 0.4s;
}

.loading span:nth-child(3) {
  background-color: #95B46A;
  animation-delay: 0.8s;
}

@keyframes dots {
  50%{
    opacity: 0;
    transform: scale(0.7) translate(10px);
  }
}