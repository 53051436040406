* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html, body {
  height: 100%;
  font-size: 1rem;
  color: #252A34;
  font-family: 'Metal Mania', roboto;
  letter-spacing: .1rem;
}

a {
  text-decoration: none;
  font-weight: bold;
  color:inherit;
}

button {
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 4px;
}

.tooltip {
  font-size: .7rem;
  animation-name: uploadopen;
  animation-duration: 2s;
}


@media screen and (min-width: 350px) {
  html, body {
    font-size: 1.025rem;
  }
}

@media screen and (min-width: 800px) {
  html, body {
    font-size: 1.1rem;
  }
  p {
    font-size: 1rem;
  }
}