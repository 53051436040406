.footer {
  background-color:#020202;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #363732;
  padding-right: .5em;
  padding-left: .5em;
  font-size: .9rem;
}

.footer-ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.footer-icon {
  display: inline;
  filter: brightness(1.5);
  margin-right: 1em;
}

.footer-icon:hover {
  color: #95B46A;
}

@media screen and (min-width: 600px) {
  .footer{
    justify-content: flex-end;
    font-size: 1.125rem;
  }
  .footer-p {
    margin-right: 2em;
  }
}