.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh - 5.25rem);
  background-color: #040403;
  color: #FDECEF;
}

.landing-page {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 1200px;
}

.setup-ul {
  list-style-type: none;
  padding: 0;
  text-align: left;
  padding: 0 1.5em ;
}

.landing-page-signin {
  margin-top: 1em;
  border-radius: 3px;
  width: 90px;
  font-size: 1.125rem;
  font-weight: bold;
  color: #95B46A;
  background: none;
  border: none;
}

.landing-page-signup {
  margin-top: 1em;
  border-radius: 3px;
  width: 90px;
  font-size: 1.125rem;
  color: #95B46A;
  background: none;
  border: none;
}

.landing-page-signin:hover, .landing-page-signup:hover {
  color: #95B46A;
  background: none;
  border: 2px solid #95B46A;
}

.welcome-p {
  padding: 2em;
}
