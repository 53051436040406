.login-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, .85);
}

.login-container {
  background-color: white;
  margin: 10% auto;
  height: 55%;
  width: 60%;
  box-shadow: 0 5px 8px 0 rgba(255, 255, 255, 0.3), 0 7px 20px 0 rgba(255, 255, 255, 0.17);
  animation-name: uploadopen;
  animation-duration: 1s;
  text-align: center;
}

.login-close-btn {
  color: #ccc;
  font-size: 40px;
  color: grey;
  font-weight: bold;
  margin-right: .3em;
}

.login-form {
  margin: .5em auto;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.login-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.login-close-btn:hover,
.login-close-btn:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.login-form > label {
  margin-top: .3em;
  color: #040403;
}

.login-form > input {
  font-size: 1rem;
  margin-top: .2em;
}

.login-submit {
  margin-top: 1em;
  border-radius: 3px;
  font-size: 1.25rem;
  color: #95B46A;
  background: #040403;
  border: none;
  align-self: center;
  padding: .5em;
  align-self: center;
}

.login-submit:hover, .login-submit:focus {
  border: 4px solid #95B46A;
} 

.sys-message {
  display: block;
}
