.upload-modal {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
}

.upload-container {
  opacity: 1;
  background-color: white;
  margin: 10% auto;
  height: 70%;
  width: 60%;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.17);
  animation-name: uploadopen;
  animation-duration: 1s;
  text-align: center;
}

.close-btn {
  color: #ccc;
  float: right;
  font-size: 30px;
  color: grey;
}

.upload-form {
  margin: 1em auto;
  height: 65%;
  width: 75%;
}

.close-btn:hover,
.close-btn:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.drop-box {
  margin: 0 auto;
  border: 1px dashed black;
  height: 75%;
  width: 75%;
}

.file-drop-target-preview {
  max-height: 70%;
  margin: 0 auto;
  display: block;
  visibility: hidden;
}

input[type='file'] {
  width: 90px;
  color: transparent;
  margin-bottom: .3em;
}

.or {
  display: block;
  margin-bottom: .3em;
  color: #DE1A1A;
}

.upload-btn {
  margin-top: 1em;
}

.file-drop {
  height: 100%;
  width: 100%;
}

.file-drop > .file-drop-target {
  height: 100%;
}

.file-drop > .file-drop-target.file-drop-dragging-over-frame {
  background-color: silver;
}
.file-drop > .file-drop-target.file-drop-dragging-over-target {
  color: #95B46A;
  box-shadow: 0 0 13px 3px #95B46A;
}

@keyframes uploadopen {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}