.user-page {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2em;
}

.profile-pic {
  max-width: 100px;
}

.personal-section {
  width: 80%;
  max-width: 700px;
}

.create-rant-textarea {
  max-width: 690px;
  margin: 0 auto;
}

.rant-section {
  margin-top: 1em;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.bio-ul {
  list-style-type: none;
  line-height: 1.3em;
}

.divider {
  border: 2px solid grey;
  width: 80%;
  box-shadow: seagreen;
  margin-bottom: 1.3em;
  margin-top: 1.3em;
}

/* Media queries */

@media screen and (min-width: 500px) {
  .profile-pic {
    max-width: 150px;
  }
}
@media screen and (min-width: 850px) {
  .profile-pic {
    max-width: 180px;
    max-height: 200px;
  }
  .user-page {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-areas:
      'profile content content content'
      'create content content content'
      'bar content content content'
      'empty content content content';
    margin-bottom: 2em;
  }
  .personal-section {
    grid-area: profile;
    justify-self: center;
    width: 70%;
  }
  .create-rant {
    grid-area: create;
    max-width: 70%;
    justify-self: center;
  }
  .divider {
    display: none;
  }
  .rant-section {
    grid-area: content;
    max-width: 750px;
    justify-self: center;
  }
  .rant-content-box {
    width: 80%;
  }
}

@media screen and (min-width: 1050px) {
  .profile-pic {
    max-width: 250px;
    max-height: 225px;
  }
}
