.regis-form {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  width: 70%;
}

.regis-form > label {
  margin-top: .3em;
  margin-bottom: .3em;
}

.regis-form > input {
  font-size: 1rem;
}

.regis-btn {
  margin-top: 1em;
  border-radius: 3px;
  font-size: 1.25rem;
  color: #95B46A;
  background: none;
  border: none;
  align-self: center;
  padding: .5em;
}

.regis-btn:hover {
  color: #95B46A;
  background: none;
  border: 2px solid #95B46A;
}

